body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    color: #333;
}

.college_header {
    background: #ffb400;
    color: white;
    padding: 20px;
    text-align: center;
}

.container {
    width: 90%;
    margin: 20px auto;
}

.section {
    margin-bottom: 40px;
}

.highlight_college {
    background: #ffb400;
    color: white;
    padding: 20px;
    font-size: 1.5em;
}

.info-box {
    background: #f9f9f9;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ddd;
}

.info-box p {
    margin: 0;
}

.program-list {
    list-style: none;
    padding: 0;
}

.program-list li {
    background: #f9f9f9;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
}

.contact-box-college {
    background: #ffe9c4;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
}

.contact-box p {
    margin: 10px 0;
}

.footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
}

h2, h3 {
    color: #ffb400;
}

ul {
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
}