.innovativeCurriculomn_container {
    /* height: 100vh; */
    background-color: white;
    padding: 5rem 20rem;
}

.innovativeCurriculomn_heading {
    color: #FFB393;
    font-size: 4.5rem;
}

.innovativeCurriculomn_para {
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}
.curriculomnTable{
    display: flex;
}
.curriculomnTable > p {
    color: #FFB393;
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 2.7rem;
    width: 50%;
    /* text-align: left; */
}