.readyToDeploy_container {
    /* height: 100vh; */
    background-color: white;
    padding: 5rem 20rem;
}

.readyToDeploy_heading {
    color: #FFB393;
    font-size: 4.5rem;
}

.readyToDeploy_para {
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}

.deploayCardHeading {
    font-size: 2.25rem;
    color: #F4CAB8;
    font-family: "Brygada 1918", serif;
    margin: 1.5rem 0;
}
/* .deployCard{

} */
.deployCard_container{
    display: flex;
}
.deployCardPara {
    font-size: 1.7rem;
    color: #F4CAB8;
    font-family: "Montserrat", sans-serif;
}