.industryProgram_container{
    background-color: white;
    /* height: 100vh; */
    padding: 5rem 20rem;
    color: #F4CAB8;
}
.industryProgram_heading{
    font-size: 4.5rem;
    color: #FFB393;
    margin-bottom: 2rem;
}
.industryProgram_para{
    font-size: 1.7rem;
    color: #FFB393;
    margin: 2rem 0;
}
.industryProgram_card{
    width: calc(33.3333% - 1rem);
    /* margin: 1rem; */
    padding: 1.5rem;
    /* flex: 1 1 auto; */
    background-color: #4d1529;
}