.ourApproach_container{
    background-color: white;
    /* height: 140vh; */
    padding: 1rem 15rem;
    display: flex;
}
.ourApproach_container_left{
    background-image: url('../Assets/aaproach.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: inherit;
    width: 100%;
}
.ourApproach_container_right{
    padding: 8rem 6rem;
}
.ourApproach_heading{
    color: #FFB393;
    font-size: 4.5rem;
}
.ourApproach_para{
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}
.approachCardIcon{
    height: 4.2rem;
    width: 4.2rem;
    color: rgb(244, 202, 184);
 }
 .approachCardHeading{
    font-size: 2.25rem;
    color: #F4CAB8;
    font-family: "Brygada 1918", serif;
    margin: 1.5rem 0;
 }
 .approachCardPara{
    font-size: 1.7rem;
    color: #F4CAB8;
    font-family: "Montserrat", sans-serif;
 }