.flexibleDelievery_container{
    /* height: 120vh; */
    background-color: white;
    padding: 5rem 20rem;
    display: flex;
}
.flexibleDelievery_container_right{
    background-image: url('../Assets/flexibleDelievery.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
}
.flexibleDelievery_heading{
    color: #FFB393;
    font-size: 4.5rem;
}
.flexibleDelievery_para{
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}
.delieveryCardHeading{
    font-size: 2.25rem;
    color: #F4CAB8;
    font-family: "Brygada 1918", serif;
}
.delieveryCardPara{
    font-family: "Montserrat", sans-serif;
    color: #F4CAB8;
    font-size: 1.7rem;
}