body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

header {
    background: #444;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    margin-bottom: 30px;
}

.section h1, .section h2 {
    color: #222;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.flex-container .box-student {
    flex: 1;
    min-width: 300px;
    padding: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.highlight {
    background: #222;
    color: white;
    padding: 15px 20px;
}

footer {
    background: #444;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
}

a {
    color: #0066cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}