.empoweringEducators_container{
    /* height: 100vh; */
    background-color: white;
    padding: 5rem 20rem;
    color: #000000;
}
.empoweringEducators_heading{
    font-size: 4.5rem;
}
.empoweringEducators_para{
    font-size: 1.7rem;
    margin: 3rem 0;
}
.educatorCardContainer{
    display: flex;
}
.educatorCardImage{
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 26rem;
}
.educatorCard_Heading{
    font-size: 2.25rem;
    margin: 1rem 0;
}
.educatorCard_Para{
    font-size: 1.7rem;
}