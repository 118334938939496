.industryPartnerships_container {
    /* height: 100vh; */
    background-color: white;
    padding: 5rem 20rem;
}

.industryPartnerships_heading {
    color: #FFB393;
    font-size: 4.5rem;
}

.industryPartnerships_para {
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}
.partnershipCard_container{
    display: flex;
    /* width: 100%; */
    flex-wrap: wrap;
}
.partnershipCardHeading {
    font-size: 2.25rem;
    color: #F4CAB8;
    font-family: "Brygada 1918", serif;
}

.partnershipCardPara {
    font-family: "Montserrat", sans-serif;
    color: #F4CAB8;
    font-size: 1.7rem;
    margin: 0.5rem 0;
    padding: 1rem 0;
}