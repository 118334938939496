.hero_container {
    background-color: white;
    /* height: 90vh; */
    padding: 5rem 20rem;
}

.hero_heading {
    color: #FFB393;
    font-size: 6.2rem;
    line-height: 7.8rem;
}

.hero_para {
    color: #F4CAB8;
    font-size: 1.7rem;
    margin: 3rem 0;
}

.hero_btn {
    background-color: #FFB393;
    color: black;
    font-size: 1.2rem;
    padding: 1.2rem 1.8rem;
    border-radius: 5px;
}

@media (max-width: 1200px) {
    .hero_container {
        padding: 5rem 15rem;
    }
}