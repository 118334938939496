.handsOnLearning_container {
    /* height: 100vh; */
    background-color: white;
    padding: 5rem 20rem;
}

.handsOnLearning_heading {
    color: #FFB393;
    font-size: 4.5rem;
}

.handsOnLearning_para {
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.learningCardIcon{
    color: #FFB393;
    font-size: 2.5rem;
    /* transform: rotate(90deg); */
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px 0 -5px;
}
.learningCardIcon:hover{
    background-color: white;
}
.deployCardLine{
    height: 50px;
    width: 0.1px;
    margin: 5px 20px 0 5px;
    background-color: #f4cab8;
}
.learningCardPara {
    color: #FFB393;
    font-size: 1.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}