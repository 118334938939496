.industryNeed_container {
    background-color: white;
    /* height: 100vh; */
    padding: 5rem 20rem;
    color: #F4CAB8;
}

.industryNeed_heading {
    color: #FFB393;
    font-size: 4.5rem;
}

.industryNeed_para {
    color: #FFB393;
    font-size: 1.7rem;
    margin: 3rem 0;
}

.industryNeed_card {
    width: calc(33.3333% - 1rem);
}