.navbar_container{
    /* width: 100%; */
    height: 8rem;
    background-color: #5c2438;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 5rem; */
}
.navbar_left{
    background-image: url('../../Asset/navbar/logo.jpg');
    background-position: center;
    background-size: cover;
    width: 20rem;
    height: 8rem;
}
.navbar_icon{
    text-decoration: none;
    font-size: 1.8rem;
    margin-right: 1.5rem;
    text-transform: uppercase;
    color: white;
}