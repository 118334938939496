@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1{
  font-family: "Brygada 1918", serif;
}
p{
  font-family: "Montserrat", sans-serif;
  line-height: 2.7rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  font-size: 62.5%;
}

@media screen and (max-width: 998px) {
  html{
    font-size: 55%;
  }
}

@media (max-width: 768px) {
  html{
    font-size: 50%;
  }
}
@media (max-width: 400px) {
  html{
    font-size: 45%;
  }
}